<!--
 * @Editors: pan long
 -->
<template>
  <div>
    <Header title="xlaidian"></Header>
    <BannerTitle title="xlaidian"></BannerTitle>
    <div class="bldBox">
      <div class="introduce wow slideInUp">{{ $t("bld.XldsummaryIntro") }}</div>
      <div class="upLine">{{ $t("bld.XupLine") }}</div>
      <ul class="wow slideInUp">
        <li>
          <img src="@/assets/images/xld3.png" alt="" />
          <span>{{ $t("bld.sumList.Xtips1") }}</span>
        </li>
        <li>
          <img src="@/assets/images/xld4.png" alt="" />
          <span>{{ $t("bld.sumList.Xtips2") }}</span>
        </li>
        <li>
          <img src="@/assets/images/xld5.png" alt="" />
          <span>{{ $t("bld.sumList.Xtips3") }}</span>
        </li>
      </ul>
      <div class="show wow slideInUp">
        <div>
          <img src="@/assets/images/xld1.png" alt="" />
          <span>{{ $t("bld.xDesc1") }}</span>
        </div>
        <div>
          <img src="@/assets/images/xld2.png" alt="" />
          <span>
            {{ $t("bld.xDesc2") }}
          </span>
        </div>
      </div>
    </div>
    <Hotline></Hotline>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../../components/header.vue";
import BannerTitle from "../../components/bannerTitle.vue";
import Footer from "../../components/footer.vue";
import Hotline from "../../components/hotline.vue";
export default {
  name: "baolaidian",
  components: {
    BannerTitle,
    Header,
    Footer,
    Hotline
  }
};
</script>

<style lang="scss">
.bldBox {
  width: 1000px;
  margin: auto;
  .introduce {
    font-size: 16px;
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
    line-height: 30px;
    text-align: center;
  }
  .upLine {
    width:240px;
    height:60px;
    background:rgba(241,241,241,1);
    font-size:16px;
    font-family:PingFangSC-Regular,PingFang SC;
    font-weight:400;
    color:rgba(34,34,34,1);
    line-height: 60px;
    text-align: center;
    margin: 50px auto;
  }
  ul {
    margin-top: 107px;
    display: flex;
    margin-bottom: 112px;
    justify-content: space-between;
    li {
      width: 270px;
      img {
        width: 72px;
        height: 72px;
        display: block;
        margin: auto;
      }
      span {
        font-weight: 600;
        color: rgba(51, 51, 51, 1);
        line-height: 33px;
        font-size: 20px;
        text-align: center;
        display: block;
        margin-top: 20px;
      }
    }
  }
  .show {
    clear: both;
    overflow: hidden;
    padding: 0 93px;
    margin-bottom: 112px;
    div {
      width: 271px;
      float: left;
      span {
        text-align: center;
        font-weight: 400;
        color: rgba(102, 102, 102, 1);
        line-height: 30px;
        margin-top: 25px;
        display: block;
      }
    }
    div:first-child {
      margin-right: 272px;
    }
  }
}
</style>
